import { openEmail } from '../helpers/interactionHelper'

const PrivacyScreen = () => {
  return (
    <div className='container'>
      <div className='row' style={{ marginTop: '4rem' }}>
        <div className='col'>
          {/* Intro */}
          <h1>Need help?</h1>
          <p>
            Please contact us via the feedback form in app, leaving your email
            account / phone number if you require a reply. Or you can reach out
            to us via email below. Please note that we're a small team, but will
            do our best to respond as quickly as we can.
          </p>
          <p
            onClick={() => openEmail('vocal')}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            hello@volt.co.uk
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyScreen
