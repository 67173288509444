import { useEffect, useRef } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from 'react-router-dom'
import HomePage from './pages/Home'
import SupportPage from './pages/Support'
import PrivacyPage from './pages/Privacy'
import Folio from './pages/Folio'
import Graphql from './pages/Graphql'
import './styles/styles.scss'
import { startAnalytics, logPage } from './helpers/analyticsHelper'
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
if (isDev)
  console.log(`🔭 GraphQL Explorer: `, `${window.location.origin}/graphql`)
startAnalytics()

const Analytics = () => {
  const lastLocation = useRef(null)
  let location = useLocation()
  useEffect(() => {
    const { pathname } = location
    if (pathname === lastLocation.current) return
    const page = pathname === '/' ? 'home' : pathname.replace(/\//, '')
    const timeout = setTimeout(() => logPage(page), 300)
    lastLocation.current = pathname
    return () => clearTimeout(timeout)
  }, [location])
  return null
}

function App () {
  return (
    <div className='App'>
      {/* Router */}
      <Router>
        {/* Analytics */}
        <Analytics />
        {/* Pages */}
        <Routes>
          {/* Privacy (for Monarch app + Facebook) */}
          <Route path='/vocal/help' element={<SupportPage />} />
          <Route path='/vocal/support' element={<SupportPage />} />
          <Route path='/vocal/privacy' element={<PrivacyPage />} />

          {/* Folio */}
          <Route path='/folio/:page' element={<Folio />} />
          <Route path='/folio' element={<Folio />} />

          {/* Home */}
          <Route path='/' element={<HomePage />} />

          {/* GraphQL Explorer */}
          {isDev && <Route path='/graphql' element={<Graphql />} />}
        </Routes>
      </Router>
    </div>
  )
}

export default App
